import React from 'react'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container, Grid } from '@material-ui/core'
import { H4, H6 } from '@system'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    '& .icon-wrapper:first-child .phase:before, & .icon-wrapper:last-child .phase:after':
      {
        background: 'none',
      },
  },
  banner: {
    margin: 'auto',
  },
  phase: {
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
    display: 'block',
  },
  title: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'center',
    padding: '55px 0px 30px',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      padding: '55px 0px 0px',
    },
  },
  iconWrapper: {},
}))

const TitleWithImageModule = (props) => {
  const classes = useStyles({ classes: { iconWrapper: 'icon-wrapper' } })
  return (
    <SbEditable content={props.blok}>
      <Container fixed>
        <Box py={4} className={classes.outerContainer}>
          <H4 className={classes.title}>{props.blok.title}</H4>
          {!!props.blok.image &&
            props.blok.image.map((blok) => (
              <Box key={blok._uid}>
                {renderBlok({
                  ...blok,
                  className: classes.banner,
                  maxWidth: '96%',
                })}
              </Box>
            ))}
          <Grid direction="row" className={classes.mainContainer} container>
            {props.blok.phases.map((blok) => (
              <Grid item xs={3} key={blok._uid} className={classes.iconWrapper}>
                <H6 className={classes.phase}>{renderBlok(blok)}</H6>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </SbEditable>
  )
}
export default TitleWithImageModule
